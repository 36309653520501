/* ///////////////////////////////////////////////////

HARDCOVER
Table of Contents

1. container
2. background & color
3. opening cover, back cover and pages
4. position, transform y transition
5. events
6. Bonus
	- Cover design
	- Ribbon
	- Figcaption

/////////////////////////////////////////////////////*/

/*
	1. container
*/

.book {
  perspective: 1000px;
  transform-style: preserve-3d;
}

/*
	2. background & color
*/

/* HARDCOVER FRONT */
.hardcover_front,
.page,
.hardcover_back,
.book_spine {
  list-style: none;
  padding: 0;
}

.hardcover_front li:first-child {
  background-color: #161616;
  backface-visibility: hidden;
}

/* reverse */
.hardcover_front li:last-child {
  background: #fffbec;
}

/* HARDCOVER BACK */
.hardcover_back li:first-child {
  background: #fffbec;
}

/* reverse */
.hardcover_back li:last-child {
  background: #fffbec;
}

.book_spine li:first-child {
  background: #384c4c;
}
.book_spine li:last-child {
  background: #374d4d;
}

/* thickness of cover */

.hardcover_front li:first-child:after,
.hardcover_front li:first-child:before,
.hardcover_front li:last-child:after,
.hardcover_front li:last-child:before,
.hardcover_back li:first-child:after,
.hardcover_back li:first-child:before,
.hardcover_back li:last-child:after,
.hardcover_back li:last-child:before,
.book_spine li:first-child:after,
.book_spine li:first-child:before,
.book_spine li:last-child:after,
.book_spine li:last-child:before {
  background: #999;
}

/* page */

.page > li {
  box-shadow: inset 0px -1px 2px rgba(50, 50, 50, 0.1),
    inset -1px 0px 1px rgba(150, 150, 150, 0.2);
  border-radius: 0px 5px 5px 0px;
}

/*
	3. opening cover, back cover and pages
*/

.hardcover_front {
  transform: rotateY(-34deg) translateZ(8px);
  z-index: 100;
}

.hardcover_back {
  transform: rotateY(-15deg) translateZ(-8px);
}

.page li:nth-child(1) {
  transform: rotateY(-28deg);
}

.page li:nth-child(2) {
  transform: rotateY(-30deg);
}

.page li:nth-child(3) {
  transform: rotateY(-32deg);
}

.page li:nth-child(4) {
  transform: rotateY(-34deg);
}

.page li:nth-child(5) {
  transform: rotateY(-36deg);
}

/*
	4. position, transform & transition
*/

.hardcover_front,
.hardcover_back,
.book_spine,
.hardcover_front li,
.hardcover_back li,
.book_spine li {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

.hardcover_front,
.hardcover_back {
  transform-origin: 0% 100%;
}

.hardcover_front {
  transition: all 0.8s ease, z-index 0.6s;
}

/* HARDCOVER front */
.hardcover_front li:first-child {
  cursor: default;
  user-select: none;
  transform: translateZ(2px);
}

.hardcover_front li:last-child {
  transform: rotateY(180deg) translateZ(2px);
}

/* HARDCOVER back */
.hardcover_back li:first-child {
  transform: translateZ(2px);
}

.hardcover_back li:last-child {
  transform: translateZ(-2px);
}

/* thickness of cover */
.hardcover_front li:first-child:after,
.hardcover_front li:first-child:before,
.hardcover_front li:last-child:after,
.hardcover_front li:last-child:before,
.hardcover_back li:first-child:after,
.hardcover_back li:first-child:before,
.hardcover_back li:last-child:after,
.hardcover_back li:last-child:before,
.book_spine li:first-child:after,
.book_spine li:first-child:before,
.book_spine li:last-child:after,
.book_spine li:last-child:before {
  position: absolute;
  top: 0;
  left: 0;
}

/* HARDCOVER front */
.hardcover_front li:first-child:after,
.hardcover_front li:first-child:before {
  width: 4px;
  height: 100%;
}

.hardcover_front li:first-child:after {
  transform: rotateY(90deg) translateZ(-2px) translateX(2px);
}

.hardcover_front li:first-child:before {
  transform: rotateY(90deg) translateZ(158px) translateX(2px);
}

.hardcover_front li:last-child:after,
.hardcover_front li:last-child:before {
  width: 4px;
  height: 160px;
}

.hardcover_front li:last-child:after {
  transform: rotateX(90deg) rotateZ(90deg) translateZ(80px) translateX(-2px)
    translateY(-78px);
}
.hardcover_front li:last-child:before {
  box-shadow: 0px 0px 30px 5px #333;
  transform: rotateX(90deg) rotateZ(90deg) translateZ(-140px) translateX(-2px)
    translateY(-78px);
}

/* thickness of cover */

.hardcover_back li:first-child:after,
.hardcover_back li:first-child:before {
  width: 4px;
  height: 100%;
}

.hardcover_back li:first-child:after {
  transform: rotateY(90deg) translateZ(-2px) translateX(2px);
}
.hardcover_back li:first-child:before {
  transform: rotateY(90deg) translateZ(158px) translateX(2px);
}

.hardcover_back li:last-child:after,
.hardcover_back li:last-child:before {
  width: 4px;
  height: 160px;
}

.hardcover_back li:last-child:after {
  transform: rotateX(90deg) rotateZ(90deg) translateZ(80px) translateX(2px)
    translateY(-78px);
}

.hardcover_back li:last-child:before {
  box-shadow: 10px -1px 80px 20px #666;
  transform: rotateX(90deg) rotateZ(90deg) translateZ(-140px) translateX(2px)
    translateY(-78px);
}

/* BOOK SPINE */
.book_spine {
  transform: rotateY(60deg) translateX(-5px) translateZ(-12px);
  width: 16px;
  z-index: 0;
}

.book_spine li:first-child {
  transform: translateZ(2px);
}

.book_spine li:last-child {
  transform: translateZ(-2px);
}

/* thickness of book spine */
.book_spine li:first-child:after,
.book_spine li:first-child:before {
  width: 4px;
  height: 100%;
}

.book_spine li:first-child:after {
  transform: rotateY(90deg) translateZ(-2px) translateX(2px);
}

.book_spine li:first-child:before {
  transform: rotateY(-90deg) translateZ(-12px);
}

.book_spine li:last-child:after,
.book_spine li:last-child:before {
  width: 4px;
  height: 16px;
}

.book_spine li:last-child:after {
  transform: rotateX(90deg) rotateZ(90deg) translateZ(8px) translateX(2px)
    translateY(-6px);
}

.book_spine li:last-child:before {
  box-shadow: 5px -1px 100px 40px rgba(0, 0, 0, 0.2);
  transform: rotateX(90deg) rotateZ(90deg) translateZ(-210px) translateX(2px)
    translateY(-6px);
}

.page,
.page > li {
  position: absolute;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
}

.page {
  width: 100%;
  height: 98%;
  top: 1%;
  left: 3%;
  z-index: 10;
}

.page > li {
  width: 100%;
  height: 100%;
  /* background: linear-gradient(to right, #e1ddd8 0%, #fffbf6 100%); */
  background-position: center center;
  background-size: contain;
  transform-origin: left center;
  transition-property: transform;
  transition-timing-function: ease;
}

.page > li:nth-child(1) {
  transition-duration: 0.6s;
  background-image: url("../assets/bookPage1.webp");
}

.page > li:nth-child(2) {
  transition-duration: 0.6s;
  background-image: url("../assets/bookPage2.webp");
}

.page > li:nth-child(3) {
  transition-duration: 0.4s;
  background-image: url("../assets/bookPage3.webp");
}

.page > li:nth-child(4) {
  transition-duration: 0.5s;
  background-image: url("../assets/bookPage4.webp");
}

.page > li:nth-child(5) {
  transition-duration: 0.6s;
  background-image: url("../assets/bookPage5.webp");
}

/*
	5. events
*/

.book:hover > .hardcover_front {
  transform: rotateY(-145deg) translateZ(0);
  z-index: 0;
}

.book:hover > .page li:nth-child(1) {
  transform: rotateY(-30deg);
  transition-duration: 1.5s;
}

.book:hover > .page li:nth-child(2) {
  transform: rotateY(-35deg);
  transition-duration: 1.8s;
}

.book:hover > .page li:nth-child(3) {
  transform: rotateY(-118deg);
  transition-duration: 1.6s;
}

.book:hover > .page li:nth-child(4) {
  transform: rotateY(-130deg);
  transition-duration: 1.4s;
}

.book:hover > .page li:nth-child(5) {
  transform: rotateY(-140deg);
  transition-duration: 1.2s;
}

/*
	6. Bonus
*/

/* cover CSS */

.coverDesign {
  position: absolute;
  background-image: url("../assets/bookFront.webp");
  background-position: center center;
  background-size: cover;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1;
  backface-visibility: hidden;
}

.coverDesign::after {
  background: red;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.coverDesign h1 {
  color: #fff;
  font-size: 2.2em;
  letter-spacing: 0.05em;
  text-align: center;
  margin: 54% 0 0 0;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1);
}

.coverDesign p {
  color: #f8f8f8;
  font-size: 1em;
  text-align: center;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1);
}

/* Media Queries */
@media screen and (max-width: 37.8125em) {
  .align > li {
    width: 100%;
    min-height: 440px;
    height: auto;
    padding: 0;
    margin: 0 0 30px 0;
  }

  .book {
    margin: 0 auto;
  }

  figcaption {
    text-align: center;
    width: 320px;
    top: 250px;
    padding-left: 0;
    left: -80px;
    font-size: 90%;
  }
}
